import * as React from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import { AxiosResponse } from "axios";
import api from "../api";
import {
  OrderType,
  OrderCreate,
  OrderItem,
  User,
  OrderOrderItem,
  OrderOrderItemCreate,
  StatusField,
  OrderStatusCreate,
  Status,
} from "../../api/api";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment/moment";

interface AddOrderFormDialogProps {
  open: boolean;
  handleClose: () => void;
  addOrder: (order: any) => void;
  onRefresh?: () => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  selectedItems: readonly string[],
  theme: any,
) => {
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const AddOrderFormDialog: React.FC<AddOrderFormDialogProps> = ({
  open,
  handleClose,
  addOrder,
  onRefresh,
}) => {
  const theme = useTheme();

  const [orderCreate, setOrderCreate] = React.useState<OrderCreate>({
    date: dayjs().toISOString(),
    user_ids: [],
    type: OrderType.Wedding,
  });

  const [orderCreate2, setOrderCreate2] = React.useState<OrderCreate>({
    date: null,
    user_ids: [],
    type: OrderType.Wedding,
  });

  const [orderItems, setOrderItems] = React.useState<OrderItem[]>([]);
  const [selectedOrderItems, setSelectedOrderItems] = React.useState<string[]>(
    [],
  );
  const [otherUsers, setOtherUsers] = React.useState<User[]>([]);
  const [selectedOtherUsers, setSelectedOtherUsers] = React.useState<string[]>(
    [],
  );
  const [users, setUsers] = React.useState<User[]>([]);
  const [statuses, setStatuses] = React.useState<Status[]>([]);
  const [selectedStatusId, setSelectedStatusId] = React.useState<string>("");

  const [selectedPreWeddingStatusId, setSelectedPreWeddingStatusId] =
    React.useState<string>("");

  const [preWeddingShooting, setPreWeddingShooting] =
    React.useState<boolean>(false);

  const [preWeddingStatus, setPreWeddingStatus] = React.useState<string>("");
  const [selectedPreWeddingItems, setSelectedPreWeddingItems] = React.useState<
    string[]
  >([]);
  const [preWeddingOrderDate, setPreWeddingOrderDate] =
    React.useState<Dayjs | null>(null);
  const [weddingOrderDate, setWeddingOrderDate] = React.useState<Dayjs | null>(
    null,
  );

  React.useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const response = await api.orderItems.getOrderItemsOrderItemsGet();
        const data = response.data.contents;
        setOrderItems(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des order items :",
          error,
        );
      }
    };
    fetchOrderItems();

    const fetchUsers = async () => {
      try {
        const response =
          await api.users.getUsersRoleUsersRoleTargetRoleGet("client");
        const data = response.data.contents;
        setUsers(data);
        setOtherUsers(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error,
        );
      }
    };
    fetchUsers();

    const fetchStatuses = async () => {
      try {
        const response =
          await api.status.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
            "order",
          );
        const data = response.data.contents;
        setStatuses(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des statuts :", error);
      }
    };
    fetchStatuses();
  }, []);

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    name: string,
  ) => {
    const { value } = event.target;
    if (name === "type" && value !== OrderType.Wedding) {
      setPreWeddingShooting(false);
    }
    if (name === "user_id") {
      setOrderCreate((prevState: OrderCreate) => ({
        ...prevState,
        user_ids: [value, ...prevState.user_ids.slice(1)],
      }));
    } else if (name === "status") {
      setSelectedStatusId(value);
    } else {
      setOrderCreate((prevState: OrderCreate) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlePreWeddingSelectChange = (
    event: SelectChangeEvent<string>,
    name: string,
  ) => {
    const { value } = event.target;
    if (name === "type" && value !== OrderType.Wedding) {
      setPreWeddingShooting(false);
    }
    if (name === "user_id") {
      setOrderCreate((prevState: OrderCreate) => ({
        ...prevState,
        user_ids: [value, ...prevState.user_ids.slice(1)],
      }));
    } else if (name === "status") {
      setSelectedPreWeddingStatusId(value);
    } else {
      setOrderCreate((prevState: OrderCreate) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderItems(typeof value === "string" ? value.split(",") : value);
  };

  const handleMultiSelectChangeUser = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedOtherUsers(typeof value === "string" ? value.split(",") : value);
  };

  const handlePreWeddingMultiSelectChange = (
    event: SelectChangeEvent<string[]>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedPreWeddingItems(
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleDateChange = (date: Dayjs | null) => {
    setWeddingOrderDate(date);
    setOrderCreate((prevState: OrderCreate) => ({
      ...prevState,
      date: date ? date.toISOString() : "",
    }));
  };

  const handlePreWeddingDateChange = (date: Dayjs | null) => {
    setPreWeddingOrderDate(date);
    setOrderCreate2((prevState: OrderCreate) => ({
      ...prevState,
      date: date ? date.toISOString() : prevState.date,
    }));
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const userIds = {
        ...orderCreate,
        user_ids: [orderCreate.user_ids[0], ...selectedOtherUsers],
      };

      let createdOrders: any[] = [];

      if (orderCreate.type === OrderType.Wedding) {
        const weddingOrderCreate = {
          ...orderCreate,
          type: OrderType.Wedding,
          user_ids: [orderCreate.user_ids[0], ...selectedOtherUsers],
        };

        const weddingOrderResponse: AxiosResponse<any> =
          await api.orders.postOrderOrdersPost(userIds);
        const weddingOrderId = weddingOrderResponse.data.order_id;

        let weddingOrderItems: OrderOrderItem[] = [];

        for (const orderItemId of selectedOrderItems) {
          const orderOrderItemCreate: OrderOrderItemCreate = {
            order_id: weddingOrderId,
            order_item_id: orderItemId,
          };

          const orderOrderItemResponse =
            await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(
              orderOrderItemCreate,
            );
          weddingOrderItems.push(orderOrderItemResponse.data);
        }

        const weddingOrderDateFormatted = moment(
          weddingOrderResponse.data.date,
        ).format("YYYY/MM/DD HH:mm:ss");

        const weddingOrderData = {
          ...weddingOrderResponse.data,
          name: selectedOtherUsers
            .map((userId) => {
              const user = otherUsers.find((user) => user.user_id === userId);
              return user
                ? `${user.first_name} ${user.last_name}`
                : "Unknown User";
            })
            .join(", "),
          date: weddingOrderDateFormatted,
          order_order_items: weddingOrderItems,
          status: selectedStatusId,
        };

        createdOrders.push(weddingOrderData);

        const weddingOrderStatusCreate: OrderStatusCreate = {
          complete_date: new Date().toISOString(),
          order_id: weddingOrderData.order_id,
          status_id: selectedStatusId,
          description: null,
        };
        await api.orderStatus.postOrderStatusOrderStatusesPost(
          weddingOrderStatusCreate,
        );

        if (preWeddingShooting) {
          const preWeddingOrderCreate = {
            ...orderCreate2,
            type: OrderType.PreWedding,
            user_ids: [orderCreate.user_ids[0], ...selectedOtherUsers],
          };

          const preWeddingOrderResponse: AxiosResponse<any> =
            await api.orders.postOrderOrdersPost(preWeddingOrderCreate);
          const preWeddingOrderId = preWeddingOrderResponse.data.order_id;

          let preWeddingOrderItems: OrderOrderItem[] = [];

          for (const orderItemId of selectedPreWeddingItems) {
            const orderOrderItemCreate: OrderOrderItemCreate = {
              order_id: preWeddingOrderId,
              order_item_id: orderItemId,
            };

            const orderOrderItemResponse =
              await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(
                orderOrderItemCreate,
              );
            preWeddingOrderItems.push(orderOrderItemResponse.data);
          }

          const preWeddingOrderDateFormatted = moment(
            preWeddingOrderResponse.data.date,
          ).format("YYYY/MM/DD HH:mm:ss");

          const preWeddingOrderData = {
            ...preWeddingOrderResponse.data,
            name: selectedOtherUsers
              .map((userId) => {
                const user = otherUsers.find((user) => user.user_id === userId);
                return user
                  ? `${user.first_name} ${user.last_name}`
                  : "Unknown User";
              })
              .join(", "),
            date: preWeddingOrderDateFormatted,
            order_order_items: preWeddingOrderItems,
            status: selectedPreWeddingStatusId,
          };

          createdOrders.push(preWeddingOrderData);

          const preWeddingOrderStatusCreate: OrderStatusCreate = {
            complete_date: new Date().toISOString(),
            order_id: preWeddingOrderData.order_id,
            status_id: selectedPreWeddingStatusId,
            description: null,
          };
          await api.orderStatus.postOrderStatusOrderStatusesPost(
            preWeddingOrderStatusCreate,
          );
        }
      } else {
        const orderResponse: AxiosResponse<any> =
          await api.orders.postOrderOrdersPost(userIds);
        const orderId = orderResponse.data.order_id;

        let allOrderOrderItem: OrderOrderItem[] = [];

        for (const orderItemId of selectedOrderItems) {
          const orderOrderItemCreate: OrderOrderItemCreate = {
            order_id: orderId,
            order_item_id: orderItemId,
          };

          const orderOrderItemResponse =
            await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(
              orderOrderItemCreate,
            );
          const orderOrderItems = orderOrderItemResponse.data;

          allOrderOrderItem.push(orderOrderItems);
        }

        const orderDate = moment(orderResponse.data.date).format(
          "YYYY/MM/DD HH:mm:ss",
        );

        const data = {
          ...orderResponse.data,
          name: selectedOtherUsers
            .map((userId) => {
              const user = otherUsers.find((user) => user.user_id === userId);
              return user
                ? `${user.first_name} ${user.last_name}`
                : "Unknown User";
            })
            .join(", "),
          date: orderDate,
          order_order_items: allOrderOrderItem,
        };

        createdOrders.push(data);

        const orderStatusCreate: OrderStatusCreate = {
          complete_date: new Date().toISOString(),
          order_id: orderId,
          status_id: selectedStatusId,
          description: null,
        };
        await api.orderStatus.postOrderStatusOrderStatusesPost(
          orderStatusCreate,
        );
      }

      handleClose();

      onRefresh && onRefresh();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Add Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the necessary information to add an order:
        </DialogContentText>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            value={orderCreate.user_ids[0] || ""}
            onChange={(event) => handleSelectChange(event, "user_id")}
          >
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard">
          <InputLabel id="other-users-select-label">Other Users</InputLabel>
          <Select
            labelId="other-users-select-label"
            id="other-users-select"
            multiple
            value={selectedOtherUsers}
            onChange={handleMultiSelectChangeUser}
            input={
              <OutlinedInput id="select-multiple-chip" label="Other Users" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      otherUsers.find((user) => user.user_id === value)
                        ?.first_name || value
                    }
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {otherUsers.map((user) => (
              <MenuItem
                key={user.user_id}
                value={user.user_id}
                style={getStyles(
                  user.first_name || "",
                  selectedOtherUsers,
                  theme,
                )}
              >
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-type-select-label">Order Type</InputLabel>
          <Select
            labelId="order-type-select-label"
            id="order-type-select"
            value={orderCreate.type || ""}
            onChange={(event) => handleSelectChange(event, "type")}
          >
            {Object.values(OrderType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-status-select-label">Order Status</InputLabel>
          <Select
            labelId="order-status-select-label"
            id="order-status-select"
            value={selectedStatusId}
            onChange={(event) => handleSelectChange(event, "status")}
          >
            {statuses.map((status) => (
              <MenuItem key={status.status_id} value={status.status_id}>
                {status.status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense" variant="standard" required>
          <InputLabel id="order-items-select-label">Order Items</InputLabel>
          <Select
            labelId="order-items-select-label"
            id="order-items-select"
            multiple
            value={selectedOrderItems}
            onChange={handleMultiSelectChange}
            input={
              <OutlinedInput id="select-multiple-chip" label="Order Items" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      orderItems.find((item) => item.order_item_id === value)
                        ?.description || value
                    }
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {orderItems.map((item) => (
              <MenuItem
                key={item.order_item_id}
                value={item.order_item_id}
                style={getStyles(item.description, selectedOrderItems, theme)}
              >
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box mt={1}>
            <DatePicker
              label="Order Date"
              onChange={(date) => handleDateChange(date)}
              value={orderCreate.date ? dayjs(orderCreate.date) : null}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Box>
        </LocalizationProvider>

        {orderCreate.type === OrderType.Wedding && (
          <FormControl fullWidth margin="dense" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preWeddingShooting}
                    onChange={(event) =>
                      setPreWeddingShooting(event.target.checked)
                    }
                  />
                }
                label="Pre-Wedding Shooting"
              />
            </FormGroup>
          </FormControl>
        )}

        {preWeddingShooting && orderCreate.type === OrderType.Wedding && (
          <>
            <FormControl fullWidth margin="dense" variant="standard" required>
              <InputLabel id="pre-wedding-status-select-label">
                Pre-Wedding Status
              </InputLabel>
              <Select
                labelId="pre-wedding-status-select-label"
                id="pre-wedding-status-select"
                value={selectedPreWeddingStatusId}
                onChange={(event) =>
                  handlePreWeddingSelectChange(event, "status")
                }
              >
                {statuses.map((status) => (
                  <MenuItem key={status.status_id} value={status.status_id}>
                    {status.status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense" variant="standard" required>
              <InputLabel id="pre-wedding-items-select-label">
                Pre-Wedding Order Items
              </InputLabel>
              <Select
                labelId="pre-wedding-items-select-label"
                id="pre-wedding-items-select"
                multiple
                value={selectedPreWeddingItems}
                onChange={handlePreWeddingMultiSelectChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Order Items"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          orderItems.find(
                            (item) => item.order_item_id === value,
                          )?.description || value
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {orderItems.map((item) => (
                  <MenuItem
                    key={item.order_item_id}
                    value={item.order_item_id}
                    style={getStyles(
                      item.description,
                      selectedPreWeddingItems,
                      theme,
                    )}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box mt={1}>
                <DatePicker
                  label="Pre-Wedding Order Date"
                  onChange={(date) => handlePreWeddingDateChange(date)}
                  value={preWeddingOrderDate}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Box>
            </LocalizationProvider>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderFormDialog;
