import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../api";
import { OrderStaff, User } from "../../api";
import { useEffect } from "react";
import { config } from "../../components/api";
import { useNavigate } from "react-router-dom";

const today = dayjs();

const newTheme = createTheme({});

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: Dayjs[] },
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !outsideCurrentMonth &&
    highlightedDays.some((highlightedDay) => day.isSame(highlightedDay, "day"));

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={
        isSelected
          ? {
              backgroundColor: "rgb(189,189,189)",
              borderRadius: "50%",
              color: "#888888",
            }
          : undefined
      }
    />
  );
}

interface CalendarProps {
  user: User;
  handleDateChange: (date: any) => void;
  highlightedDays: Dayjs[];
  setHighlightedDays: React.Dispatch<React.SetStateAction<any[]>>;
  orderStaffs?: Map<string, any>;
  setOrderStaffs?: React.Dispatch<React.SetStateAction<Map<string, any>>>;
  orderStaffToDelete?: string;
  setOrderStaffToDelete?: React.Dispatch<React.SetStateAction<any>>;
}

function Calendar({
  user,
  handleDateChange,
  highlightedDays,
  setHighlightedDays,
  orderStaffToDelete,
  setOrderStaffToDelete,
  orderStaffs,
  setOrderStaffs,
}: CalendarProps) {
  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const fetchHighlightedDays = async (date: Dayjs) => {
    const controller = new AbortController();
    requestAbortController.current = controller;

    try {
      setIsLoading(true);

      let response;
      if (config.role === "staff") {
        response =
          await api.orderStaffs.getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
            user.user_id,
          );
      } else if (
        config.role &&
        ["admin", "manager", "client"].includes(config.role)
      ) {
        response = await api.orders.getOrdersOrdersGet();
      }

      if (response && response.data && response.data.contents) {
        const daysToHighlight: Dayjs[] = [];
        const orderStaffMap = new Map<string, any>();

        response.data.contents.forEach((item: any) => {
          if (item.date) {
            const orderStaffDate = dayjs(item.date);
            daysToHighlight.push(orderStaffDate);
            orderStaffMap.set(orderStaffDate.format("YYYY-MM-DD"), item);
          }

          if (item.order && item.order.date) {
            const orderDate = dayjs(item.order.date);
            daysToHighlight.push(orderDate);
            orderStaffMap.set(orderDate.format("YYYY-MM-DD"), item);
          }
        });

        setHighlightedDays(daysToHighlight);
        if (setOrderStaffs) setOrderStaffs(orderStaffMap);
      }
    } catch (error) {
      console.error("Error fetching highlighted days:", error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchHighlightedDays(today);
    return () => requestAbortController.current?.abort();
  }, [user]);

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    fetchHighlightedDays(date);
  };

  const handleDateClick = (date: Dayjs) => {
    let orderStaff = null;

    if (orderStaffs) {
      orderStaff = orderStaffs.get(date.format("YYYY-MM-DD"));
    }

    if (orderStaff?.date) {
      if (setOrderStaffToDelete) {
        setOrderStaffToDelete(orderStaff);
      }
      handleDateChange(date);
    } else if (orderStaff?.order?.date) {
      if (setOrderStaffToDelete) {
        setOrderStaffToDelete(orderStaff);
      }
      handleDateChange(date);
    } else {
      handleDateChange(date);
    }
    navigate(`/order?date=${date.format("YYYY-MM-DD")}`);
  };

  return (
    <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          defaultValue={today}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          onChange={(date: Dayjs) => handleDateClick(date)}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
            } as any,
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default Calendar;
